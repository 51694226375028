<template>
  <div class="yb-grid-actions">
    <slot />
  </div>
</template>

<style lang="postcss">

.ag-theme-alpine div.yb-grid-actions {
  @apply h-full mx-4 pl-4 absolute top-0 right-0 flex flex-row flex-nowrap items-center opacity-0 transition-opacity duration-500 ease-in-out;
}

.ag-theme-alpine .ag-row-hover div.yb-grid-actions {
  @apply opacity-100;
}

.ag-theme-alpine div.yb-grid-actions .yb-button {
  @apply ml-0 my-0 mr-1 p-0 yb-link;
}

.ag-theme-alpine div.ag-row-selected div.yb-grid-actions {
  @apply !bg-yb-gray-faint dark:!bg-yb-gray-alt-medium dark:!text-yb-gray-alt-lightest;
}

.ag-theme-alpine div.ag-row-hover:not(.ag-row-selected) div.yb-grid-actions {
  @apply !bg-yb-gray-alt-lightest dark:!bg-yb-gray-mediumer;
}

.ag-theme-alpine div.yb-grid-actions {
  @apply yb-body-background;
}

</style>
